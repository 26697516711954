import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import classnames from 'classnames';

import CardRelatedArticles from 'components/partials/RelatedArticles/components/CardRelatedArticles';

const LoadOnScroll = ({ data }) => {
  const [chankIndex, setChankIndex] = useState(1);
  const loader = useRef(null);

  const items = useMemo(() =>
    data.slice(0, chankIndex * 12),
    [chankIndex, data]
  );

  const endList = useMemo(() =>
    items.length === data.length,
    [data.length, items.length]
  );

  const handleObserver = useCallback((entries) => {
    const target = entries[0];

    if (target.isIntersecting && !endList) {
      setChankIndex((prev) => prev + 1);
    }
  }, [endList]);

  useEffect(() => {
    const option = {
      root: null,
      // rootMargin: "20px",
      threshold: 0.2
    };

    const observer = new IntersectionObserver(handleObserver, option);

    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return (
    <>
      <ul className="lg:pt-40 sm:flex sm:justify-start sm:items-stretch sm:flex-wrap sm:-mx-2.5 ">
        {items.map((article, i) => (
          <li
            key={article.id}
            className="mb-5 sm:flex-2 sm:px-2.5 lg:flex-3 lg:mb-10"
          >
            <CardRelatedArticles
              data={article}
              cardHover="oc-radial-hover oc-radial-hover--orange"
            />
          </li>
        ))}
      </ul>
      <div ref={loader} className={classnames({ 'pt-20 lg:pt-14': endList })} />
    </>
  )
}

export default LoadOnScroll;
