import React, { useCallback } from 'react';
import { navigate } from 'gatsby';
import qs from 'query-string';

import classnames from 'classnames';

const Item = ({ category, selected }) => {
  const isSelected = selected.some((slug) => category.slug === slug);

  // TODO: реализация на основе путей
  // const handleClick = useCallback(() => {
  //   const preparedPath =
  //     pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname;

  //   const result = isSelected
  //     ? preparedPath
  //       .split('/')
  //       .filter((item) => item !== category.slug)
  //       .join('/')
  //     : `${preparedPath}/${category.slug}`;

  //   navigate(result + '/');
  // }, [category.slug, isSelected, pathname]);

  // TODO: Реализация на основе параметров
  const handleClick = useCallback(() => {
    const resultCategories = isSelected ? selected.filter(item => item !== category.slug) : [...selected, category.slug];

    const stringified = qs.stringify({ category: resultCategories });

    navigate('?' + stringified);
  }, [category.slug, isSelected, selected]);

  return (
    <button
      type="button"
      onClick={handleClick}
      className={classnames(
        'border border-mine-shaft h-12 rounded-full px-7 py-3 flex items-center justify-center hover:text-white hover:bg-mine-shaft hover:opacity-70 transition-all',
        {
          'text-white bg-mine-shaft hover:opacity-90': isSelected,
        },
      )}
    >
      {category.name}
    </button>
  );
};

export default Item;
