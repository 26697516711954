import {
  useStaticQuery,
  graphql
} from 'gatsby';

const allGuideArticleText = graphql `
  query {
    allWpGuideArticleText {
      nodes {
        uri
        title
        slug
        id
        excerpt
        databaseId
        content
        categories {
          nodes {
            uri
            slug
            name
          }
        }
        tags {
          nodes {
            uri
            slug
            name
          }
        }
        featuredImage {
          node {
            altText
            srcSet
            sourceUrl
          }
        }
      }
    }
  }
`;

const useAllGuideArticleText = () => {
  const {
    allWpGuideArticleText: {
      nodes
    },
  } = useStaticQuery(allGuideArticleText);

  return nodes;
};

export default useAllGuideArticleText;
