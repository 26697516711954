import React from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Link from 'components/Link';
import Icon from 'components/ui/Icon';

const HeaderLibrary = ({ data, link }) => {
  const windowWidth = useWindowWidth({ initialWidth: 640 });

  return (
    <div className="fluid-container">
      {windowWidth > 640 ? (
        <Link to={link.url} className="flex items-center mb-16 uppercase">
          <Icon name="arrow-left" className="w-5 h-5 mr-3" />
          {link.title}
        </Link>
      ) : null}

      <h1 className="oc-h1 mb-9 lg:mb-14">
        Lung Cancer Articles<sup>({data})</sup>
      </h1>
    </div>
  );
};

export default HeaderLibrary;
