import React, { useState } from 'react';
import classnames from 'classnames';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import Item from './Item';
import Icon from 'components/ui/Icon';

import { list } from './styles.module.scss';

const Filters = ({ categories, selected }) => {
  const windowWidth = useWindowWidth({ initialWidth: 992 });

  const [hiddenCategories, setHiddenCategories] = useState(true);

  const toShow = windowWidth > 992 ? 5 : 2;

  const showMore = categories.length > toShow;

  const categoriesToDisplay = showMore
    ? hiddenCategories
      ? categories.slice(0, toShow)
      : categories
    : categories;

  return (
    <div
      className={classnames(
        'fluid-container flex justify-start items-center',
        list,
      )}
    >
      <ul className="flex flex-wrap items-center gap-5">
        {categoriesToDisplay.map((category) => (
          <li key={category.slug}>
            <Item category={category} selected={selected} />
          </li>
        ))}
      </ul>

      {showMore && hiddenCategories && (
        <button
          onClick={() => setHiddenCategories((state) => !state)}
          type="button"
          className="ml-5 border border-mine-shaft rounded-full w-12 h-12 flex justify-center items-center flex-none hover:text-white hover:bg-mine-shaft hover:opacity-70 transition-all"
        >
          <span>+{categories.length - toShow}</span>
        </button>
      )}

      {showMore && (
        <p className="pl-10 lg:pl-40 ml-auto self-end lg:self-start flex-none">
          <button
            type="button"
            onClick={() => setHiddenCategories((state) => !state)}
            className={classnames(
              'transition-all bg-mine-shaft w-12 h-12 rounded-full flex justify-center items-center  ',
            )}
          >
            <Icon
              name="dropdown"
              className={classnames(
                'transition-all w-9 h-9 text-white transform duration-300',
                hiddenCategories ? 'rotate-0' : 'rotate-180',
              )}
            />
          </button>
        </p>
      )}
    </div>
  );
};

export default Filters;
