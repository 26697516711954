import {
  useStaticQuery,
  graphql
} from 'gatsby';

const allGuideArticleCard = graphql `
  query {
    allWpGuideArticleCard {
      nodes {
        uri
        title
        slug
        id
        excerpt
        databaseId
        content
        categories {
          nodes {
            uri
            slug
            name
          }
        }
        tags {
          nodes {
            uri
            slug
            name
          }
        }
        featuredImage {
          node {
            altText
            srcSet
            sourceUrl
          }
        }
      }
    }
  }
`;

const useAllGuideArticleCard = () => {
  const {
    allWpGuideArticleCard: {
      nodes
    },
  } = useStaticQuery(allGuideArticleCard);

  return nodes;
};

export default useAllGuideArticleCard;
