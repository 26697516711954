import React from 'react';

import LoadOnScroll from './LoadOnScroll';

import { wrapper } from './styles.module.scss';

const ArticlesList = ({ data }) => {
  return (
    <section className="relative overflow-hidden pt-10 pb-20 lg:pb-40 lg:pt-0">
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none -z-5"></div>

      <div className={wrapper}>
        <div className="fluid-container z-10">
          <div className="z-10">
            <LoadOnScroll data={data} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArticlesList;
