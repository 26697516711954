import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useLocation } from '@reach/router';
import qs from 'query-string';

import ArticlesList from './ArticlesList';
import AboutUsPromo from 'components/partials/AboutUsPromo';
import Filters from './Filters';
import HeaderLibrary from './Header/index';

import useAllGuideArticleText from 'hooks/graphql/useAllGuideArticleText';
import useAllGuideArticleCard from 'hooks/graphql/useAllGuideArticleCard';
import useOptions from 'hooks/graphql/useOptions';

import { wrap } from './styles.module.scss';

const LungCancerGuide = ({ params, ...props }) => {
  const relatedGuideArticleText = useAllGuideArticleText();
  const relatedGuideArticleCard = useAllGuideArticleCard();
  const options = useOptions();

  const allArticles = useMemo(
    () => [...relatedGuideArticleText, ...relatedGuideArticleCard],
    [relatedGuideArticleCard, relatedGuideArticleText],
  );

  const allCategories = useMemo(
    () =>
      allArticles.reduce(
        (arr, { categories }) => [
          ...arr,
          ...categories.nodes.filter(
            ({ slug }) => !arr.some((el) => el.slug === slug),
          ),
        ],
        [],
      ),
    [allArticles],
  );

  // TODO: Реализация на основе параметров
  const { search } = useLocation();
  const { category } = qs.parse(search);

  const categoriesInParameters = useMemo(() =>
    category ? (
      Array.isArray(category) ?
        category :
        [category]
    ) : [],
    [category]);

  // TODO: реализация на основе путей
  // const categoriesInParameters = useMemo(
  //   () => params?.categories?.split('/').filter((item) => item) || [],
  //   [params?.categories],
  // );

  const articlesList = useMemo(
    () =>
      categoriesInParameters.length > 0
        ? allArticles.filter((article) =>
          categoriesInParameters.some((category) =>
            article.categories.nodes.some((item) => item.slug === category),
          ),
        )
        : allArticles,
    [allArticles, categoriesInParameters],
  );

  const {
    general: {
      libraryPage: { libraryPageBackToGuideLink },
    },
  } = options.general;

  return (
    <div className={classnames('pt-40 bg-merino', wrap)}>
      <div className="relative z-1">
        <HeaderLibrary
          data={articlesList.length}
          link={libraryPageBackToGuideLink}
        />

        <Filters
          categories={
            allCategories
          }
          selected={categoriesInParameters}
        />
        <ArticlesList data={articlesList} />
        <AboutUsPromo />
      </div>
    </div>
  );
};

export default LungCancerGuide;
